<!-- HEADER -->
<section class="home__header">
    <div class="header__content wrapper">
        <a class="header__logo" [href]="" alt="Explorer Yummypets">
            <img [src]="logoSVG" />
        </a>
       <h1 class="header__title">
            <span class="clip-from-top">We connect.&nbsp;</span> 
            <span class="clip-from-bottom ">We talk.&nbsp;</span>
            <span class="clip-from-top"> We pet care.</span>
        </h1>
        <p class="header__desc" i18n="@@landing.header.desc">
            Explorer is a collaborative mission dedicated to the well-being of animals around the world.
        </p>

        <div class="header__cta mt-10">
            <a class="btn btn--primary btn--sm" [routerLink]="[isAuthenticated ? '/dashboard' : '/signup']" i18n="@@landing.header.btn.participate">Participate</a>
            <a class="btn btn--transparent-on-light btn--sm" [routerLink]="['/panel']" i18n="@@landing.header.btn.hiw">How it works</a>
        </div>
    </div>
</section>

<!-- COUNTRIES -->
<section class="home__countries">
    <div><h2 i18n="@@landing.countries.title">We are currently looking for pet owners from</h2></div>
    <ul class="countries__list">
        <li>
            <a aria-disabled="true" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--FR"></i>
                <span i18n="@@global.country.france">France</span>
            </a>
        </li>
        <li>
            <a aria-disabled="true" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--UK"></i>
                <span i18n="@@global.country.uk">United Kingdom</span>
            </a>
        </li>
        <li>
            <a aria-disabled="true" routerLink="/" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--US"></i>
                <span i18n="@@global.country.us">United States</span>
            </a>
        </li>
        <li>
            <a aria-disabled="true" routerLink="/" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--CA"></i>
                <span i18n="@@global.country.canada">Canada</span>
            </a>
        </li>
        <li>
            <a aria-disabled="true" routerLink="/" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--BR"></i>
                <span i18n="@@global.country.brazil">Brazil</span>
            </a>
        </li>
        <li>
            <a aria-disabled="true" routerLink="/" class="btn btn--sp btn--transparent-on-light">
                <i class="icon icon--bg i--MX"></i>
                <span i18n="@@global.country.mexico">Mexico</span>
            </a>
        </li>
    </ul>
    <div><p class="countries__join" i18n="@@landing.countires.join">We want you to join the discussion and lend your voice to empower pet parents around the world.</p></div>
</section>

<!-- HOW IT WORKS -->
<section class="home__hiw" exScrollAnimation>
    <h2 i18n="@@landing.hiw.title">How it works</h2>
    <div class="hiw__items">
        <hr class="hiw__line" />
        <div class="hiw__item">
            <div class="hiw__circle">
                <img src="https://d2xec21l9srv8z.cloudfront.net/v2/icon_panel.png">
                <div class="hiw__icon">1</div>
            </div>
            <p class="hiw__text" i18n="@@landing.hiw.create">Create an account to join the panel.</p>
        </div>
        <div class="hiw__item">
            <div class="hiw__circle">
                <img src="https://d2xec21l9srv8z.cloudfront.net/v2/icon_hands.png">
                <div class="hiw__icon">2</div>
            </div>
            <p class="hiw__text" i18n="@@landing.hiw.share">Share your opinion by participating in our surveys.</p>
        </div>
        <div class="hiw__item">
            <div class="hiw__circle">
                <img src="https://d2xec21l9srv8z.cloudfront.net/v2/icon_survey.png">
                <div class="hiw__icon">3</div>
            </div>
            <p class="hiw__text" i18n="@@landing.hiw.reward"> You are rewarded for your participation.</p>
        </div>
        <div class="hiw__item">
            <div class="hiw__circle">
                <img src="https://d2xec21l9srv8z.cloudfront.net/v2/icon_nature.png">
                <div class="hiw__icon">4</div>
            </div>
            <p class="hiw__text" i18n="@@landing.hiw.contribute">We donate free meals to animals in need.</p>
        </div>
    </div>
</section>

<section class="home__asso">
	<h2 i18n="@@landing.asso.title">1 survey = 2 meals donated to shelters.</h2>
	<div class="asso__card mt-5">
		<!-- <span>${{ ((stats | async)?.jackpot) | number:'1.0-0' }}</span> -->
        <span class="asso__left">
            <span class="asso__partner" i18n="@@asso.partner">In partnership with</span>
            <img src="https://d2ocidupsqths7.cloudfront.net/img-d/voice/png/logo-voice.png">
        </span>
		<p class="asso__right">
            <span class="asso__desc" i18n="@@asso.desc.one">As the day of today</span>
            <strong class="asso__meals" i18n="@@asso.meals">{{ ((stats$ | async )!.num_points *10) | number:'1.0-0':locale }} meals</strong>
            <span i18n="asso.desc.two">have been donated to shelters around the world.</span>

		</p>
	</div>

    <a 
    class="btn btn--transparent-on-light btn--sm mt-5"
    routerLink="/shelters"
    i18n="@@global.btn.learn">Learn more</a>
</section> 
 
<section class="home__mobile">
    <div class="grid-auto-fit">
        <div class="mobile__desc"> 
            <h2 i18n="@@landing.mobile.title">
                Get the app
            </h2>
            <p class="mt-10"  i18n="@@landing.mobile.desc">
                Participate in our surveys and respond to our questions of the week directly from your mobile phone. Thank you for participating in our panel. 
            </p>
        </div>
        <div class="mobile__links">
            <div class="d-flex">
                <a class="app-android" href="https://apps.apple.com/app/6478925036">
                    <img [src]="cdn.v3 + 'badges/svg/AppStore_' + locale +'.svg'"/>
                </a>
                <a class="app-ios" href="https://play.google.com/store/apps/details?id=com.yummypets.explorer">
                    <img [src]="cdn.v3 + 'badges/svg/GooglePlay_' + locale +'.svg'"/>
                </a>
            </div>
        </div>
    </div>
</section>


<section class="home__data">
    <div class="grid-auto-fit data__wrapper">
        <div class="data__trans">
            <h2 class="data__title">
                <span class="icon-title__content" i18n="@@landing.tata.title">Data transparency</span>
            </h2>
            <p class="data__desc mt-5" i18n="@@landing.data.desc">
                Explorer attaches great importance to the security of your personal information. All of the data that we collect is secure and complies with GDPR standards. It is confidential and is only used to establish statistics for our studies and surveys. The data collected by Explorer is neither sold nor communicated to a third party.
            </p>
        </div>
        <div class="data__animation" exScrollAnimation>
            <div>
                <div class="data__striped"></div>
                <div class="data__circle">
                    <div class="data__icon"><img src="https://yp-explorer.s3.amazonaws.com/v2/shield.svg" style="width:100%;"></div>
                    <span>GDPR</span>
                </div>
                <div class="data__rect"></div>               
            </div>
        </div>
    </div>
</section>

<section class="home__cta pt-10 pb-10">
    <p class="panel__cta" i18n="@@landing.cta.join">Join the panel. Join the discussion. Lend your voice to our current studies.</p>
    <div class="text-center mt20">
      <a 
        class="btn btn--primary btn--l mt-10"
        routerLink="/signup"
        i18n="@@global.btn.participate">Participate</a>
    </div>
</section>
